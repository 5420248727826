import React,{useState,useEffect}from "react"
import Layout from "@components/layout"
import { Link } from "gatsby"
import Meta from "@components/meta"
import RequiredTag from "@components/required-tag"
import RadioBtn from "@components/radio-btn"
import Checkbox from "@components/checkbox"
import InputForm from "@components/input-form"
import TextBox from "@components/text-box"
import BottomCta from "@components/bottom-cta"
import '@styles/pages/contact.scss'
import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0,delay: 600}
}
const Box = posed.div(transition)

const ContactPage = (props) => {
  const [path, setPath] = useState(false);

  useEffect(()=> {
    setPath(true);
    return ()=> setPath(false);
  })
  return (
  <Layout>
    <Meta
      title="お問い合わせ"
      desc="KnockLearnのお問い合わせページです。"
    />
    <PoseGroup>
      <Box key={"contact"} pose={path ? "visible" : "hidden"}>
      <section class="page">
        <div class="page-top">
          <div class="page-top__container">
            <ol>
              <li><Link to="/">TOP&nbsp;>&nbsp;</Link></li>
              <li>講師紹介</li>
            </ol>
            <h2 class="page-top__heading">CONTACT</h2>
            <p class="page-top__lead">お問い合わせ</p>
          </div>
        </div>
        <section class="contact-form">
          <h3 class="heading2">お問い合わせフォーム</h3>
          <p class="txt-lh_175">「サービスを利用したい」「持病を抱えており配慮が必要」など、<br class="tb_none"/>気になる点はお気軽にご質問ください。<br/>取材のご依頼などについては内容を確認次第、担当者よりご連絡させて頂きます。</p>
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div class="contact-item">
                <div class="contact-item__left">
                  <span class="contact-item__heading">お問い合わせ内容 </span>
                  <RequiredTag/>
                </div>
                <div class="contact-item__form">
                  <RadioBtn type="radio" name="contact-type" value={"サービスを利用したい"} required={true} txt={"サービスを利用したい"} />
                  <RadioBtn type="radio" name="contact-type" value={"聞きたいことがある"} required={true} txt={"聞きたいことがある"} />
                </div>
            </div>

            <div class="contact-item">
                <div class="contact-item__left">
                  <span class="contact-item__heading">お名前 </span>
                  <RequiredTag/>
                </div>
                <div class="contact-item__form">
                  <InputForm type="text" name="user-name" required={true} placeholder="例) 山田太郎"/>
                </div>
            </div>

            <div class="contact-item">
                <div class="contact-item__left">
                  <span class="contact-item__heading">メールアドレス </span>
                  <RequiredTag/>
                </div>
                <div class="contact-item__form">
                  <InputForm type="email" name="email" required={true} placeholder="例) yamadataro@knocklearn.com"/>
                </div>
            </div>

            {/* <div class="contact-item">
                <div class="contact-item__left">
                  <span class="contact-item__heading">お住まいの都道府県 </span>
                  <RequiredTag/>
                </div>
                <div class="contact-item__form">
                  <SelectForm name="pref" options={prefList} required={true}/>
                </div>
            </div> */}

            <div class="contact-item">
                <span class="contact-item__left free-word">自由記入欄<br/><span>(気になる事などご質問ください) </span></span>
                <div class="contact-item__form">
                  <TextBox name="textarea" required={false} placeholder="自由記入欄"/>
                </div>
            </div>

            <label class="checkbox">
              <input type="checkbox" name={"privacy-policy"} value={"同意"} required={true}/>
              <Link to={"/privacy"}>
                <span className="privacy-link">プライバシーポリシー</span>
              </Link>
              に同意する。
            </label>

            <p class="btn btn--primary">
              <button type="submit">送信する</button>
            </p>
          </form>
        </section>
        
      </section>
      <BottomCta/>
      </Box>
      </PoseGroup>
  </Layout>
  )
}
export default ContactPage
