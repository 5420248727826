import React,{useState,useRef} from "react"
import '@styles/components/form-components.scss'


const RadioBtn = props => {
    const [isChecked, setIsChecked] = useState(false);
    const radioHidden = useRef(null);
    const radio = useRef(null);
    const clickHandler = ()=> {
        setIsChecked(!isChecked);
    }
    return (
        <>
        <div class="radio-btn">
            {/* <label ref={radio} class={`radio ${isChecked ? "checked" : ""}`} for={"radio-" + props.value} ></label> */}
            <label>
                <input ref={radioHidden} onChange={clickHandler} id={"radio-" + props.value} class="radio-hidden" type={props.type} name={props.name} value={props.value} required={props.required}/>{props.txt}
            </label>
        </div>
        </>
    )
}
export default RadioBtn;